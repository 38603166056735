<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="sites.isLoading"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSaveForm()"
    @cancel="onCancel('DomaAiIntegration')"
  />
</template>

<script>
import { mapState, mapActions } from 'vuex';

import editableListItemCreate from '@/mixins/editableListItemCreate.js';

import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'DomaAiIntegrationNew',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      controllerName: 'DomaAiIntegration',
      pageHeader: 'Создание интеграции с "Дома АИ"',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Интеграция с "Дома АИ"',
          route: { name: 'DomaAiIntegration' },
        },
        {
          text: 'Создание интеграции с "Дома АИ"',
        },
      ],
      initialForm: {
        inn: null,
        databaseName: null,
      },
      form: null,
    };
  },

  computed: {
    ...mapState({
      sites: (state) => state.common.sites,
    }),

    fields() {
      const sites = this.sites.data;

      return [
        {
          tab: '',
          form: [
            {
              type: 'text',
              key: 'inn',
              label: 'ИНН',
            },
            {
              type: 'select',
              key: 'databaseName',
              label: 'База данных',
              list: sites,
            },
          ],
        },
      ];
    },
  },

  created() {
    this.getCommonList({ name: 'Sites' });
  },

  methods: {
    ...mapActions({
      getCommonList: 'common/getCommonList',
      createItem: 'editableList/createItem',
    }),

    onSaveForm() {
      if (this.form.databaseName) {
        const dbName =
          this.sites.data.find((site) => site.id === this.form.databaseName)
            ?.name || '';

        this.form = { ...this.form, databaseName: dbName };
      }

      this.onSave('DomaAiIntegration');
    },
  },
};
</script>
